import { FaTwitter } from 'react-icons/fa'
import { GoVerified } from 'react-icons/go'
import { IconContext } from 'react-icons'
import { IoIosHeartEmpty } from 'react-icons/io'
import React from 'react'
import linkifyHtml from 'linkifyjs/html'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    background: 'white',
    border: '1px solid #e1e8ed',
    borderRadius: 5,
    margin: '10px 0',
    padding: '17.5px 17.5px 10.15px 17.5px',
    font: 'normal normal 14px/1.4 Helvetica,Roboto,"Segoe UI",Calibri,sans-serif',
    color: '#1c2022',
    textAlign: 'left',
    '& a': {
      textDecoration: 'none'
    },
    '& a:hover': {
      color: '#3b94d9'
    }
  },
  avatar: {
    float: 'left',
    width: '36px !important',
    height: 36,
    borderRadius: '50%',
    marginRight: 9
  },
  handle: {
    position: 'relative',
    top: -5
  },
  verified: {
    color: '#1da1f2',
    position: 'relative',
    top: 4,
    left: 4
  },
  logo: {
    float: 'right',
    color: '#1ca1f2'
  },
  textPrimary: {
    color: '#1c2022'
  },
  textSecondary: {
    fontSize: 12.25,
    color: '#697882'
  },
  body: {
    marginTop: 12,
    '& a': {
      color: '#2b7bb9'
    }
  },
  footer: {
    marginTop: 5
  },
  verticalTop: {
    verticalAlign: 'top'
  },
  heart: {
    marginRight: 3
  },
  time: {
    marginLeft: 12.5
  }
})

const Tweet = ({ classes, children, time, avatar, likes, name, handle, id, verified }) => {
  const profileUrl = `https://twitter.com/${handle}`
  const profileUrlText = `${handle} on Twitter`
  const tweetUrl = `${profileUrl}/status/${id}`
  const tweetUrlText = `Tweet by ${handle} on Twitter`
  const body = linkifyHtml(children, {
    defaultProtocol: 'https',
    target: '_blank',
    className: '',
    attributes: {
      rel: 'noopener'
    }
  })

  return (
    <div className={classes.root}>
      <div>
        <a href={tweetUrl} aria-label={tweetUrlText} target='_blank' rel='noopener'>
          <IconContext.Provider value={{ className: classes.logo, size: 17.5 }}>
            <FaTwitter />
          </IconContext.Provider>
        </a>
        <a href={profileUrl} aria-label={profileUrlText} target='_blank' rel='noopener'><img src={avatar} className={classes.avatar} alt={`${children}`} title={`${children}`} /></a>
        <a href={profileUrl} aria-label={profileUrlText} target='_blank' rel='noopener'>
          <strong className={classes.textPrimary}>{name}</strong>
          {verified &&
            <IconContext.Provider value={{ className: classes.verified, size: 17.5 }}>
              <GoVerified />
            </IconContext.Provider>
          }
          <br /><span className={`${classes.textSecondary} ${classes.handle}`}>@{handle}</span>
        </a>
      </div>
      <div className={classes.body} dangerouslySetInnerHTML={{ __html: body }} />
      <div className={classes.footer}>
        <a href={tweetUrl} aria-label={tweetUrlText} target='_blank' rel='noopener' className={`${classes.textSecondary} ${classes.verticalTop}`}>
          <IconContext.Provider value={{ className: classes.heart, size: 15 }}>
            <IoIosHeartEmpty />
          </IconContext.Provider>
          {likes}
        </a>
        <a href={tweetUrl} aria-label={tweetUrlText} target='_blank' rel='noopener' className={`${classes.textSecondary} ${classes.time} ${classes.verticalTop}`}>{time}</a>
      </div>
    </div>
  )
}

export default withStyles(styles)(Tweet)
